<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="modal"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-i18n="adOns">CONFIGURE_SMS_AD_ONS</span>
    </template>
    <template v-slot>
      <div>
        <UiInput
          v-model.trim="emailConfigsData.title"
          type="text"
          name="TITLE"
          title="TITLE"
          label="TITLE"
          placeholder="TITLE"
          class="flex-1"
          rules="required"
        />
        <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
          <UiInput
            v-model="emailConfigsData.from_email"
            type="text"
            name="Email"
            title="Email"
            label="Email"
            placeholder="Email"
            class="flex-1"
            rules="email|required"
          />
          <div class="flex flex-1 gap-3 flex-col sm:flex-row">
            <UiInput
              v-model="emailConfigsData.from_title"
              type="text"
              name="EMAIL_TITLE"
              title="EMAIL_TITLE"
              label="EMAIL_TITLE"
              placeholder="EMAIL_TITLE"
              class="flex-1"
              rules="alpha_spaces|required"
            />
          </div>
        </div>
        <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
          <UiSingleSelect
            v-model="emailConfigsData.adapter_provider"
            title="PROVIDER_TYPE"
            label="title"
            class="w-full"
            reduce="id"
            :options="emailServvicesList"
            rules="required"
          />
        </div>
        <div
          v-if="emailConfigsData.adapter_provider === 'send_grid_adapter'"
          class="flex flex-col sm:flex-row gap-4 lg:gap-5"
        >
          <UiInput
            v-model="emailConfigsData.adapterConfig.api_key"
            type="text"
            name="API_KEY"
            title="API_KEY"
            label="API_KEY"
            placeholder="ENTER_API_KEY"
            class="flex-1"
            rules="required"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="adOns"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        :disabled="isLoading"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(configureEmailProvider(invalid))"
      >
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else v-i18n="adOns">
          <span>Add</span>
        </span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    // ValidationObserver,
    UiModalContainer,
    UiSingleSelect,
    UiInput,
    Loader,
  },
  props: {
    modal: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  data() {
    return {
      adOns: 'adOns',
      isLoading: false,
      emailServvicesList: [{ title: 'Send Grid Adapter', id: 'send_grid_adapter' }],
      emailConfigsData: {
        title: '',
        adapterConfig: {
          api_key: '',
        },
        adapter_provider: '',
        from_email: '',
        from_title: '',
      },
    }
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    configureEmailProvider(invalid) {
      if (invalid) return
      let payload = this.getConfigurationPayload()
      this.addNewEmailConfiguration(payload).then(() => {
        this.$emit('toggle', true)
      })
    },
    getConfigurationPayload() {
      return {
        adapter_config: {
          api_key: this.emailConfigsData.adapterConfig.api_key,
        },
        adapter_provider: this.emailConfigsData.adapter_provider,
        title: this.emailConfigsData.title,
        from_email: this.emailConfigsData.from_email,
        from_title: this.emailConfigsData.from_title,
      }
    },
    ...mapActions('addons', ['addNewEmailConfiguration']),
  },
}
</script>
