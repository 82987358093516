<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="EMAIL_AD_ONS"
      tooltip-title="EMAIL_AD_ONS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(emailProvidersList)">
      <NoRecordFound />
    </div>
    <div v-else class="bg-white mt-5 relative rounded-md border border-primary-grey">
      <div
        id="table-container"
        :class="[leftBlurArea, rightBlurArea]"
        class="overflow-x-auto rounded-lg"
        @scroll.passive="scrollDetect"
      >
        <table class="w-full table-auto whitespace-nowrap">
          <thead class="bg-bright-gray">
            <tr class="text-left">
              <th
                v-for="(head, index) in tableHeaders"
                :key="index"
                class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
              >
                <span
                  class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                >
                  {{ head }}
                </span>
              </th>
              <th
                v-permission="rootLevelPermisions"
                class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
              >
                <span
                  class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                >
                  Campus
                </span>
              </th>
              <th
                class="font-roboto font-medium text-base text-text-color py-5 text-center rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
              >
                <span
                  class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                >
                  Status
                </span>
              </th>
              <th
                class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
              >
                <span
                  class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                >
                  Actions
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(provider, index) in emailProvidersList"
              :key="index"
              class="border-t border-primary-grey text-left"
            >
              <td
                class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
              >
                <span
                  class="font-roboto font-normal text-sm text-primary-green cursor-pointer"
                  @click="redirectToDetailPage(provider.id)"
                >
                  {{
                    transformTextToCapitalize(provider.adapter_provider) ||
                    GENERAL_CONSTANTS.NOT_APPLICABLE
                  }}
                </span>
              </td>
              <td
                class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ provider.from_email }}
                </span>
              </td>
              <td
                class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  {{ provider.from_title }}
                </span>
              </td>
              <td
                v-permission="rootLevelPermisions"
                class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
              >
                <span class="font-roboto font-normal text-text-color text-sm">
                  <BadgeDisplay
                    :options="provider.campus"
                    badge-margin="ml-0"
                    :profile="true"
                    label="title"
                  />
                </span>
              </td>
              <td
                class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right xl:px-12 lg:px-8 px-4 py-0 text-center"
              >
                <div
                  class="py-2.5 min-w-1 rounded-md"
                  :class="
                    provider.is_default
                      ? 'text-primary-green bg-primary-green-trans'
                      : 'text-text-color-danger bg-light-red'
                  "
                >
                  {{
                    provider.is_default ? GENERAL_CONSTANTS.ACTIVE : GENERAL_CONSTANTS.NOT_ACTIVE
                  }}
                </div>
              </td>
              <td class="border-primary-grey w-10 px-5">
                <TableAction
                  :idx="index"
                  :current-user="provider"
                  :action-list="actionList"
                  :top="dropdownTopBottom(index, emailProvidersList, -45, -15)"
                  :drop-down="
                    dropdownTopBottom(
                      index,
                      emailProvidersList,
                      'tableDropDownbottom',
                      'tableDropDowntop',
                    )
                  "
                  :right="35"
                  :rtl-right="-245"
                  @action="typeAction"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`pagination${forceRender}`"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(providersCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecords"
    />
    <EmailAdOnsModal
      v-if="showEmailAdOnsModal"
      :modal="showEmailAdOnsModal"
      @toggle="toggleEmailAdOnsModal"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { isEmpty } from 'lodash'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { dropdownTopBottom, transformTextToCapitalize } from '@utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'

import EmailAdOnsModal from '@src/router/views/settings/ad-ons/modals/EmailAdOnsModal.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'

export default {
  components: {
    TitleWrapper,
    TableAction,
    Loader,
    Pagination,
    NoRecordFound,
    EmailAdOnsModal,
    BadgeDisplay,
  },
  mixins: [GeneralMixin, scrollMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      forceRender: 1,
      tableHeaders: ['Title', 'Sender Email', 'Sender Title'],
      emailProvidersList: [],
      filteredRecordLimit: 10,
      showPagination: false,
      currentSelectedType: '',
      providersCounts: null,
      actionList: [{ name: 'MARK_DEFAULT' }],
      currentSelectedProvider: {},
      currentUserPosition: '',
      isLoading: false,
      showEmailAdOnsModal: false,
      rootLevelPermisions: {
        basedOn: ['root-level'],
      },
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      currentCampusScope: (state) => state.layout.currentCampusScope,
    }),
  },
  watch: {
    currentCampusScope: {
      immediate: true,
      handler() {
        this.filterRecords()
      },
    },
  },
  mounted() {
    this.setRightbarData()
  },
  methods: {
    dropdownTopBottom,
    transformTextToCapitalize,
    filterRecords(range) {
      this.isLoading = true
      let payload = paginationRangeHandler(range)
      this.getEmailsProviderList(payload)
        .then((respose) => {
          this.emailProvidersList = respose.data.records
          this.providersCounts = respose.data.meta.total_records
          this.showPagination = this.providersCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          this.setRightbarData(this.providersCounts)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    redirectToDetailPage(id) {
      this.$router?.push({ name: 'email-provider-detail-page', params: { id: id } })
    },
    typeAction(action, currentType, idx) {
      this.currentSelectedProvider = currentType
      switch (action) {
        case 'MARK_DEFAULT':
          if (this.currentCampusScope && currentType && !currentType.campus)
            this.$store.commit(
              'toast/NEW',
              {
                message: `You can't set it default here. You need go to root level`,
                type: 'error',
              },
              { root: true },
            )
          else this.markEmailProviderAsDefault(currentType.id)
          break
      }
    },
    markEmailProviderAsDefault(id) {
      this.setEmailProviderDefault(id).then(() => {
        this.filterRecords()
        this.currentSelectedProvider = null
      })
    },
    setRightbarData(length = 0) {
      this.setRightbarContent({
        header: {
          title: 'EMAIL_AD_ONS',
          buttons: [
            {
              title: 'CONFIGURE',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleEmailAdOnsModal()
                },
              },
            },
          ],
        },
      })
    },
    toggleEmailAdOnsModal(payload) {
      this.showEmailAdOnsModal = !this.showEmailAdOnsModal
      if (payload) this.filterRecords()
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('addons', ['getEmailsProviderList', 'setEmailProviderDefault']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.paid {
  padding: 4px 12px;
  color: #267c26;
  background-color: #e9f2e9;
  border-radius: 3px;
}
.delay {
  padding: 4px 12px;
  color: #ffc201;
  background-color: #fff9e6;
  border-radius: 3px;
}
.due {
  padding: 4px 12px;
  color: #ea3535;
  background-color: #fce1e1;
  border-radius: 3px;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
.tableDropDown {
  top: 32px;
  right: -21px;
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(45deg);
  }
}
.info {
  top: 35px;
  border: 1px solid var(--text-color-info);
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
}
</style>
